import React from 'react';
import '../css/AnyIndustry.css';
import City from '../img/3DCity.png';

function AnyIndustry() {
  return (
    <div className="anyind-container">
      <div className="anyind-section">
        <div className="anyind-text-content">
          <h6 className="anyind-section-heading">Any Industry</h6>
          <p className="anyind-lang-91e">We know first-hand how tough it is to find talent. We've done the hard
        part for you to help you shortlist and find the best fit, from
        landscaping to healthcare and everything in between – we've got
        you covered.</p>
        </div>
        <div className='image-rs-10'>
          <img alt="AI City on a Shining Hill" src={City} className="city-image" />
        </div>
      </div>
    </div>
  );
}

export default AnyIndustry;
