// redux/actions/loginActions.js
import { createAction } from '@reduxjs/toolkit';

// Action Creators
export const loginRequested = createAction('LOGIN_REQUESTED');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailed = createAction('LOGIN_FAILED');

export const logoutRequested = createAction('LOGOUT_REQUESTED');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');

