

// import React, { useEffect, useState } from 'react';
// import '../css/Navbar.css';
// import VeeLogo from '../img/vee.png';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { logoutRequested } from '../redux/actions/authActions';

// function Navbar() {
//   const { isLoggedIn = false, user = {} } = useSelector((state) => state.auth || {});
//   const [initial, setInitial] = useState('');
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (user) {
//       setInitial(user.firstName?.charAt(0).toUpperCase());
//     }
//   }, [user]);

//   const handleLogout = () => {
//     dispatch(logoutRequested());
//     navigate('/');
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen((prev) => !prev);
//   };

//   return (
//     <nav className="navbar">
//       <div className="logo-container">
//         <img className="logo" src={VeeLogo} alt="Company Logo" width="70" height="70" />
//         <a href="/" className="company-name">VettedVoice</a>
//       </div>

//       <div className="right-container">
//       {!isLoggedIn ? (
//           <>
//             <a href='/register' 
//               className="nav-link signup-link"
//             >
//               Sign up
//             </a>
//             <a href="/contact" className="nav-link contact-link">Contact Sales</a>
//           </>
//         ) : (
//           <div className="user-avatar-container">
//             <div className="user-avatar" onClick={toggleDropdown}>
//               {initial}
//             </div>
//             {isDropdownOpen && (
//               <div className="avatar-dropdown">
//                 <button onClick={handleLogout} className="dropdown-item">Logout</button>
//               </div>
//             )}
//             <a href="/contact" className="nav-link contact-link">Contact Us</a>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// }

// export default Navbar;

import React, { useEffect, useState } from 'react';
import '../css/Navbar.css';
import VeeLogo from '../img/UpdatedVee.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequested } from '../redux/actions/authActions';

function Navbar() {
  const { isLoggedIn = false, user = {} } = useSelector((state) => state.auth || {});
  const [initial, setInitial] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for the hamburger menu

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setInitial(user.firstName?.charAt(0).toUpperCase());
    }
  }, [user]);

  const handleLogout = () => {
    dispatch(logoutRequested());
    navigate('/');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // Toggle the hamburger menu
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <img className="logo" src={VeeLogo} alt="Company Logo"  />
        <a href="/" className="company-name">VettedVoice</a>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>

      <div className={`right-links ${menuOpen ? 'active' : ''}`}>
        {!isLoggedIn ? (
          <>
            {/* <a href="/register" className="nav-link signup-link">Sign up</a> */}
            <a href="/contact" className="nav-link contact-sales">Contact Sales</a>
          </>
        ) : (
          <div className="user-avatar-container">
            <div className="user-avatar" onClick={toggleDropdown}>
              {initial}
            </div>
            {isDropdownOpen && (
              <div className="avatar-dropdown">
                <button onClick={handleLogout} className="dropdown-item">Logout</button>
              </div>
            )}
            <a href="/contact" className="nav-link contact-sales">Contact Sales</a>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;

