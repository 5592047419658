import React from 'react';
//import Navbar from '../../components/Navbar';
import './Compliance.css';
import LinksContainer from '../../components/LinksContainer';

function Compliance() {
  return (
    <div className="compliance-page">
      <div className="compliance-container">
        <div className="compliance-container">
          <h3 className="compliance-title">Compliance Resource</h3>
          <h4 className="compliance-subtitle">The Importance of Asking Compliant Questions in Interviews: Best Practices and Examples</h4>
          <p className="compliance-body">In today’s competitive job market, conducting effective and lawful interviews is crucial for organizations aiming to attract and retain top talent. One key aspect of successful interviewing is asking compliant questions—questions that adhere to legal standards and ethical guidelines. This not only ensures fairness in the hiring process but also protects your organization from potential legal repercussions. In this guide, we’ll explore the importance of compliant interview questions and provide practical examples of what to ask and what to avoid.</p>
          <h4 className="compliance-subtitle">Why Compliant Interview Questions Matter</h4>
          <h4 className="compliance-subtitle">Legal Compliance</h4>
          <p className="compliance-body">Employment laws vary by country and region, but they universally aim to prevent discrimination and protect candidates' rights. In many jurisdictions, asking inappropriate or illegal questions can lead to lawsuits and damage an organization's reputation. Ensuring your interview questions are compliant helps you stay within legal boundaries and fosters a fair hiring process.</p>
          <h4 className="compliance-subtitle">Promoting Diversity and Inclusion</h4>
          <p className="compliance-body">Compliant questions support diversity and inclusion by focusing solely on a candidate’s qualifications and suitability for the role. This approach minimizes biases and promotes a workplace culture where all employees feel valued and respected.</p>
          <h4 className="compliance-subtitle">Enhancing Candidate Experience</h4>
          <p className="compliance-body">A respectful and professional interview process leaves a positive impression on candidates, even those who aren’t selected. This can enhance your employer brand and increase the likelihood of top candidates accepting job offers.</p>
          <h4 className="compliance-subtitle">Reducing Hiring Bias</h4>
          <p className="compliance-body">Structured and compliant questions help reduce unconscious bias by standardizing the interview process. This ensures that all candidates are evaluated based on the same criteria, leading to more objective and fair hiring decisions.</p>
          <h4 className="compliance-subtitle">What to Ask: Examples of Compliant Interview Questions</h4>
          <p className="compliance-body">Focusing on the candidate’s skills, experience, and fit for the role ensures your questions
are both effective and compliant. Here are some examples:
{/* <div className='compliance-q-and-a'>
Job-Related Skills and Experience
Can you describe your experience with [specific skill or tool] and how you’ve applied it in
previous roles?
What are your key accomplishments in your last position that you believe prepare you for
this role?

How do you stay updated with the latest developments in your field?

Problem-Solving and Critical Thinking

Can you provide an example of a challenging project you managed and how you overcame
obstacles?

Describe a time when you had to make a difficult decision at work. What was the
outcome?

Teamwork and Collaboration

How do you handle conflicts within a team? Can you share an example?

Describe a successful team project you were a part of. What was your role?

Cultural Fit and Values

What motivates you in your work, and how do you align your personal goals with our
company’s mission?

How do you handle feedback and criticism? Can you provide an example?

Future Goals and Development
Where do you see yourself in five years, and how does this position align with your career
goals?
What skills are you looking to develop in your next role?
</div> */}
<div className="table-wrapper">
<table className="compliance-table">
          <thead>

            <tr>
              <th>Category</th>
              <th>Question</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Job-Related Skills</td>
              <td>Can you describe your experience with [specific skill or tool]?</td>
            </tr>
            <tr>
              <td>Past Accomplishments</td>
              <td>What are your key accomplishments in your last position?</td>
            </tr>
            <tr>
              <td>Relevant Skillset</td>
              <td>How do you stay updated with the latest developments in your field?</td>
            </tr>
            <tr>
              <td>Problem-Solving</td>
              <td>Can you provide an example of a challenging project you managed and how you overcame
              obstacles?</td>
            </tr>
            <tr>
              <td>Handling Adversity</td>
              <td>Describe a time when you had to make a difficult decision at work. What was the
              outcome?</td>
            </tr>
            <tr>
              <td>Teamwork and Collaboration</td>
              <td>How do you handle conflicts within a team? Can you share an example?</td>
            </tr>
            <tr>
              <td>Teamwork and Collaboration</td>
              <td>Describe a successful team project you were a part of. What was your role?</td>
            </tr>
            <tr>
              <td>Cultural Fit and Values</td>
              <td>What motivates you in your work, and how do you align your personal goals with our
              company’s mission?</td>
            </tr>
            <tr>
              <td>Handling Feedback and Criticism</td>
              <td>How do you handle feedback and criticism? Can you provide an example?</td>
            </tr>
            <tr>
              <td>Handling Feedback and Criticism</td>
              <td>How do you handle feedback and criticism? Can you provide an example?</td>
            </tr>
            <tr>
              <td>Future Goals and Development</td>
              <td>Where do you see yourself in five years, and how does this position align with your career
              goals?</td>
            </tr>
            <tr>
              <td>Future Upskill</td>
              <td>Where do you see yourself in five years, and how does this position align with your career
              goals?</td>
            </tr>
          </tbody>
        </table>
        </div>
        
</p>
<h4 className="compliance-subtitle">What Not to Ask: Examples of Non-Compliant Interview Questions</h4>
<p className="compliance-body">Avoid questions that can lead to discrimination or infringe on a candidate’s privacy. Here
are examples of what not to ask:
{/* <div className='compliance-q-and-a'>
Personal Information

What is your marital status?

Do you have any children or plan to start a family soon?

What is your religious affiliation?

Age-Related Questions
How old are you?

What year did you graduate from high school?

Disability and Health
Do you have any disabilities?

How many sick days did you take last year?

National Origin and Citizenship
Where were you born?
Are you a U.S. citizen? *(Note: Instead, ask if the candidate is legally authorized to work in
the country.)*

Financial Status
Do you own your home?

Have you ever filed for bankruptcy?

Criminal History (Unless Relevant)
Have you ever been arrested? *(Note: In some regions, you can ask about convictions if
relevant to the job.)*

Sexual Orientation and Gender Identity

What is your sexual orientation?
Do you plan to change your gender?
</div> */}
<div className="table-wrapper">
<table className="compliance-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Non-Compliant Question</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Personal Information</td>
              <td>What is your marital status?</td>
            </tr>
            <tr>
              <td>Children</td>
              <td>Do you have any children or plan to start a family soon?</td>
            </tr>
            <tr>
              <td>Religion</td>
              <td>What is your religious affiliation?</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>How old are you?</td>
            </tr>
            <tr>
              <td>Graduation Status</td>
              <td>What year did you graduate highschool?</td>
            </tr>
            <tr>
              <td>Disability</td>
              <td>Do you have any disabilities?</td>
            </tr>
            <tr>
              <td>Previous Sick Leave</td>
              <td>How many sick days did you take last year?</td>
            </tr>
            <tr>
              <td>National Origin and Citizenship</td>
              <td>Where were you born?</td>
            </tr>
            <tr>
              <td>National Origin and Citizenship</td>
              <td>Are you a U.S. citizen? *(Note: Instead, ask if the candidate is legally authorized to work in
                the country.)*</td>
            </tr>
            <tr>
              <td>Financial Status</td>
              <td>Do you own your home?</td>
            </tr>
            <tr>
              <td>Financial Status</td>
              <td>Have you ever filed for bankruptcy?</td>
            </tr>
            <tr>
              <td>Criminal History (Unless Relevant)</td>
              <td>Have you ever been arrested? *(Note: In some regions, you can ask about convictions if
                relevant to the job.)*</td>
            </tr>
            <tr>
              <td>Sexual Orientation and Gender Identity</td>
              <td>What is your sexual orientation?
              Do you plan to change your gender?</td>
            </tr>
          </tbody>
        </table>
        </div>
</p>
<h4 className="compliance-subtitle">Best Practices for Asking Compliant Questions</h4>
<h4 className="compliance-subtitle">Focus on Job Relevance</h4>
<p className="compliance-body">Ensure every question directly relates to the candidate’s ability to perform the job.
Irrelevant questions cannot only be non-compliant but also distract from assessing the
candidate’s true qualifications.</p>
<h4 className="compliance-subtitle">Use Structured Interviews</h4>
<p className="compliance-body">Implement a structured interview format where all candidates are asked the same set of
questions. This promotes fairness and makes it easier to compare candidates objectively.</p>
<h4 className="compliance-subtitle">Train Interviewers</h4>
<p className="compliance-body">Provide comprehensive training for all individuals involved in the hiring process. Educate
them on what constitutes compliant and non-compliant questions and the importance of
adhering to these guidelines.</p>
<h4 className="compliance-subtitle">Stay Updated with Laws</h4>
<p className="compliance-body">Employment laws can change, so it’s essential to stay informed about the latest
regulations in your region. Regularly review and update your interview questions to
maintain compliance.</p>
<h4 className="compliance-subtitle">Document the Process</h4>
<p className="compliance-body">Keep records of the questions asked and the reasons for hiring decisions. This
documentation can be invaluable if your hiring practices are ever questioned.</p>
<h4 className="compliance-subtitle">Conclusion</h4>
<p className="compliance-body">Asking compliant questions in interviews is not just a legal necessity—it’s a fundamental
aspect of building a fair, inclusive, and effective hiring process. By focusing on job-related
inquiries and avoiding discriminatory or invasive questions, organizations can attract the
best talent while fostering a positive reputation. Implementing these best practices
ensures that your interviews are both professional and compliant, setting the stage for
successful and equitable hiring outcomes.</p>

        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default Compliance;
