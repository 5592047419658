import React from 'react';
import './InterviewSC.css';
import { useNavigate } from 'react-router-dom';
import VeeLogo from '../../img/UpdatedVee.png';
import LinksContainer from '../../components/LinksContainer';

function InterviewSC() {

  const navigate = useNavigate();
  
  return (
    <div className="interviewsc-container">
      <h2>Resources</h2>
      <div className="interviewsc-grid">
        <div 
        className="interview"
        onClick={() => navigate('/interview-screen/checklist')}
        role="button"
        tabIndex={0}
      >
          <img className="interview-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Pre-Interview Checklist</h3>
          <p>Why pre-interview checklists are essential for hiring.</p>
        </div>
        <div 
        className="interview"
        onClick={() => navigate('/interview-screen/workflow-logistics')}
        role="button"
        tabIndex={0}
      >
          <img className="interview-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Interviewing Workflow</h3>
          <p>The crucial role of interviewing logistics in shaping candidate experience and company image.</p>
        </div>
        <div 
        className="interview"
        onClick={() => navigate('/interview-screen/compliance')}
        role="button"
        tabIndex={0}
      >
          <img className="interview-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Compliance</h3>
          <p>The importance of asking compliant questions in interviews.</p>
        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default InterviewSC;
