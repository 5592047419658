import { put, takeLatest } from 'redux-saga/effects';
import { logoutSuccess } from '../actions/loginActions';

function* logoutSaga() {
  try {
    localStorage.removeItem('token'); // Clear token
    yield put(logoutSuccess()); // Reset state
  } catch (error) {
    console.error('Logout failed:', error);
  }
}

export default function* watchLogout() {
  yield takeLatest('LOGOUT_REQUESTED', logoutSaga);
}
