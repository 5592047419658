import React from 'react';
import '../css/VideoContainer.css';
import Planet from '../video/connecting_planet.mp4';

function VideoContainer() {
  return (
    <div className="video-container">
      <video id="trim-video" width="100%" autoPlay loop muted playsInline>
        <source src={Planet} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      
      <div className="overlay-content">
        <h6>Your <u>People-First</u> HR Automation Platform -<br /></h6>
        <p>Easily deploy your assistant helpers powered by AI to hire and more!</p>
        {/* Optional buttons can be added here */}
      </div>
    </div>
  );
}

export default VideoContainer;
