import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import './Contact.css';
import LinksContainer from '../../components/LinksContainer';

function Contact() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Dynamically load Tally embed script
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    script.onload = () => {
      setLoading(false); // Stop loading spinner after script is loaded
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up script when unmounting
    };
  }, []);

  return (
    <div className="contact-page">
      {/* Conditionally render loading spinner or form */}
      {loading && (
        <div className="loading-spinner-container">
          <ReactLoading type="spin" color="#3498db" height={50} width={50} />
        </div>
      )}
      <h3 className="contact-text">
        Hey, we're glad you're here! We look forward to answering any questions you may have. Just fill in below, and we'll get back with you promptly.
      </h3>
      {/* Tally form iframe */}
      <iframe
        data-tally-src="https://tally.so/embed/m6DaaO?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        width="100%"
        height="534"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Contact Us"
        style={{ display: loading ? 'none' : 'block' }} // Hide iframe while loading
      ></iframe>
      <LinksContainer />
    </div>
  );
}

export default Contact;
