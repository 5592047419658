import React from 'react';
import '../css/Footer.css';

function Footer() {
  return (
    <div className="copyright-container">
      <p>Copyright &copy; 2024 VettedVoice. All rights reserved.</p>
    </div>
  );
}

export default Footer;
