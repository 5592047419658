import React from 'react';
import '../css/FeaturesContainer.css';
import VeeLogo from '../img/UpdatedVee.png';

function FeaturesContainer() {
  return (
    <div className="features-container">
      <h2>Why choose us?</h2>
      <div className="features-grid">
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Higher Quality</h3>
          <p>Better screened and interviewed candidates are a better fit for your positions.</p>
        </div>
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Better Value</h3>
          <p>We all have a budget to keep. We ensure to bring you cost effective value.</p>
        </div>
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Fast</h3>
          <p>Shrink your time to hire by more than half by automating the employment application, screening, and initial interviewing process.</p>
        </div>
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>#1 Tool</h3>
          <p>
  Voice AI for Hiring tool in the market. <a href="/product" className="product-link"> Find out why</a>? Or  
  <a href="/contact" className="contact-link"> contact us</a> today to get started!
</p>
        </div>
      </div>
    </div>
  );
}

export default FeaturesContainer;
