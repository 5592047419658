import React from 'react';
import '../css/LinksContainer.css';
import SuperVee from '../img/UpdatedVee.png';
import TermsOfService  from '../pdf/TermsAndConditions.pdf';
import PrivacyPolicy from '../pdf/VVPrivacyPolicy.pdf';

function Links() {
  return (
    <div className="footer_menu-wrapper">
       <div className="footer-branding">
        <img src={SuperVee} alt="Company Logo" className="footer-logo" />
        <div className="footer-text">
         <span className='footer-company-name'>VettedVoice</span>
         <span className="footer-slogan">Voice AI For Hiring &copy;</span>
      </div>
      </div>
      <div className="footer_column">
        <h4>Company</h4>
        <ul>
          <li><a href="/about">About Us</a></li>
          <li><a href="/product">Product</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href={TermsOfService} target="_blank" rel="noopener noreferrer" className="terms-link">
  Terms Of Service
</a></li>
          <li><a href={PrivacyPolicy} target="_blank" rel="noopener noreferrer" className="privacy-link">
  Privacy Policy
</a></li>
        </ul>
      </div>
      
      <div className="footer_column">
        <h4>Resources</h4>
        <ul>
          <li><a href="/contact">Contact Support</a></li>
          <li><a href="/faq">FAQ</a></li>
          <li><a href="/case-study">Case Study</a></li>
          <li><a href="/interview-screen">Interviewing/Screening Materials</a></li>
        </ul>
      </div>

      {/* <div className="footer_column industries-column">
        <h4 className="industries-header">Industries</h4>
        <div className="industries-wrapper">
          <ul>
            <li><a href="/industry/manufacturing">Manufacturing</a></li>
            <li><a href="/industry/landscaping">Landscaping</a></li>
            <li><a href="/industry/construction">Construction</a></li>
            <li><a href="/industry/logistics">Logistics</a></li>
            
          </ul>
          <ul>
            <li><a href="/industry/restaurant">Restaurant</a></li>
            <li><a href="/industry/hospitality">Hospitality</a></li>
            <li><a href="/industry/healthcare">Healthcare</a></li>
            <li><a href="/industry/oil-gas">Oil & Gas</a></li>
            <li><a href="/industry/warehouse">Warehouse</a></li>
            
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default Links;
