import React, { useState, useEffect, useRef } from 'react';
import { Mic, PhoneCall } from 'lucide-react';
import ReactSiriwave from 'react-siriwave';
import { motion, AnimatePresence } from 'framer-motion';
import '../css/Siri.css'; // Import the corresponding CSS file

const Siri = ({ theme, audioSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [siriWaveConfig, setSiriWaveConfig] = useState({
    theme: theme || "ios9",
    ratio: 1,
    speed: 0.2,
    amplitude: 1,
    frequency: 6,
    color: '#fff',
    cover: false,
    width: 300,
    height: 100,
    autostart: true,
    pixelDepth: 1,
    lerpSpeed: 0.1,
  });
  const audioRef = useRef(new Audio(audioSrc));

  useEffect(() => {
    const audio = audioRef.current;

    const handleAudioPlay = () => {
      setIsPlaying(true);
    };

    const handleAudioPause = () => {
      setIsPlaying(false);
    };

    audio.addEventListener('play', handleAudioPlay);
    audio.addEventListener('pause', handleAudioPause);

    return () => {
      audio.removeEventListener('play', handleAudioPlay);
      audio.removeEventListener('pause', handleAudioPause);
    };
  }, []);

  const handleToggleCall = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  useEffect(() => {
    setSiriWaveConfig((prevConfig) => ({
      ...prevConfig,
      amplitude: isPlaying ? 1.5 : 0,
      speed: isPlaying ? 0.5 : 0,
      frequency: isPlaying ? 4 : 0,
    }));
  }, [isPlaying]);

  return (
    <div className="siri-container">
      <div className="siri-inner-container">
        <motion.button
          key="callButton"
          onClick={handleToggleCall}
          className="call-button"
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
          initial={{ x: 0 }}
          animate={{ x: isPlaying ? -40 : 0 }}
          transition={{ duration: 0.3 }}
          style={{ zIndex: 10, position: 'relative' }}
        >
          <AnimatePresence>
            {!isPlaying ? (
              <motion.div
                key="micIcon"
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Mic size={20} />
              </motion.div>
            ) : (
              <PhoneCall size={20} />
            )}
          </AnimatePresence>
        </motion.button>
        <motion.div
          className="siri-wave-container"
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: '100%', opacity: 1 }}
          exit={{ width: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={{ marginLeft: '10px' }}
        >
          <ReactSiriwave {...siriWaveConfig} />
        </motion.div>
      </div>
    </div>
  );
};

export default Siri;


// src/components/Siri.js

// import React, { useState, useEffect, useRef } from 'react';
// import { Mic, PhoneCall } from 'lucide-react';
// import ReactSiriwave from 'react-siriwave';
// import { motion } from 'framer-motion';
// import '../css/Siri.css'; // Ensure this path is correct

// const Siri = ({ theme, audioSrc }) => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [siriWaveConfig, setSiriWaveConfig] = useState({
//     theme: theme || "ios9",
//     ratio: 1,
//     speed: 0.2,
//     amplitude: 1,
//     frequency: 6,
//     color: '#fff',
//     cover: false,
//     width: 300,
//     height: 100,
//     autostart: true,
//     pixelDepth: 1,
//     lerpSpeed: 0.1,
//   });
  
//   const audioRef = useRef(null); // Initialize with null

//   // Initialize and handle audio events
//   useEffect(() => {
//     if (audioSrc) {
//       audioRef.current = new Audio(audioSrc);

//       const audio = audioRef.current;

//       const handleAudioPlay = () => setIsPlaying(true);
//       const handleAudioPause = () => setIsPlaying(false);

//       audio.addEventListener('play', handleAudioPlay);
//       audio.addEventListener('pause', handleAudioPause);

//       return () => {
//         audio.removeEventListener('play', handleAudioPlay);
//         audio.removeEventListener('pause', handleAudioPause);
//         audio.pause();
//         audioRef.current = null;
//       };
//     }
//   }, [audioSrc]); // Depend on audioSrc to handle changes

//   const handleToggleCall = () => {
//     const audio = audioRef.current;
//     if (audio) {
//       if (isPlaying) {
//         audio.pause();
//       } else {
//         audio.play();
//       }
//     }
//   };

//   // Update SiriWave configuration based on playback state
//   useEffect(() => {
//     setSiriWaveConfig((prevConfig) => ({
//       ...prevConfig,
//       amplitude: isPlaying ? 1.5 : 0,
//       speed: isPlaying ? 0.5 : 0,
//       frequency: isPlaying ? 4 : 0,
//     }));
//   }, [isPlaying]);

//   return (
//     <div className="siri-container">
//       <div className="siri-inner-container">
//         <motion.button
//           key="callButton"
//           onClick={handleToggleCall}
//           className="call-button"
//           whileTap={{ scale: 0.9 }}
//           whileHover={{ scale: 1.1 }}
//           initial={{ x: 0 }}
//           animate={{ x: isPlaying ? -40 : 0 }}
//           transition={{ duration: 0.3 }}
//           style={{ zIndex: 10, position: 'relative' }}
//         >
//           <motion.div
//             key={isPlaying ? 'phoneIcon' : 'micIcon'}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             transition={{ duration: 0.2 }}
//           >
//             {isPlaying ? <PhoneCall size={20} /> : <Mic size={20} />}
//           </motion.div>
//         </motion.button>
//         <motion.div
//           className="siri-wave-container"
//           initial={{ width: 0, opacity: 0 }}
//           animate={{ width: '100%', opacity: 1 }}
//           exit={{ width: 0, opacity: 0 }}
//           transition={{ duration: 0.3 }}
//           style={{ marginLeft: '10px' }}
//         >
//           <ReactSiriwave {...siriWaveConfig} />
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// export default Siri;
