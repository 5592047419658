import React from 'react';
import { Link } from 'react-router-dom';
import './CaseStudy.css'; 
import Background from '../../img/Background.png';
import Walkin from '../../video/walkinADgif.mp4';
import LinksContainer from '../../components/LinksContainer';


function CaseStudy() {
  return (
    <div className="case-page">
      <div className="case-container">
        <div className="case-container">
          <h3 className="case-title">Case Study</h3>
          <div className="cs-background-img">
            <img alt="A background explaining a case study where VettedVoice helped improve a customer's business outlook" src={Background} className="cs-img"></img>
          </div>
          <h3 className="case-primary-subtitle">Streamlining Recruitment Processes and Boosting Quality Hires by 41%</h3>
          <h3 className="case-subtitle">Introduction</h3>
          <p className="case-body">In today's competitive job market, efficient and inclusive recruitment processes are vital for sustained growth. VettedVoice, a leading recruitment Voice AI SaaS company, specializes in providing innovative solutions that bridge language and technical barriers in hiring. This case study explores how VettedVoice partnered with MS Construction, a rapidly expanding landscaping company in Houston, TX, to revolutionize their recruitment strategy and achieve remarkable results.</p>
          <h3 className="case-subtitle">Client Overview: MS Construction</h3>
          <p className="case-body">MS Construction is a thriving landscaping company based in Houston, Texas. With continuous growth, MS Construction faces the ongoing challenge of hiring qualified Crew Members to meet increasing demand. A significant portion of their workforce and applicant pool primarily speaks Spanish, adding complexity to their recruitment processes.</p>
          <h3 className="case-subtitle">The Challenge</h3>
          <div className="case-body">
          Despite MS Construction's investment in various recruitment tools, several issues persisted:
  <table className="ideas-table">
    <tbody>
      <tr>
        <td className="lead-in">Language Barriers:</td>
        <td>Most candidates preferred communicating in Spanish, complicating interactions with HR teams not fluent in the language.</td>
      </tr>
      <tr>
        <td className="lead-in">Technical Limitations:</td>
        <td>Many applicants had lower technical skills, making online applications through the Applicant Tracking System (ATS) and career pages cumbersome.</td>
      </tr>
      <tr>
        <td className="lead-in">High Volume of In-Person and Phone Applications:</td>
        <td>Most candidates chose to apply via phone calls or walk-ins, favoring traditional paper applications over digital methods.</td>
      </tr>
      <tr>
        <td className="lead-in">HR Overload:</td>
        <td>HR personnel were stretched thin, diverting their focus from strategic tasks to assist with basic application processes.</td>
      </tr>
      <tr>
        <td className="lead-in">Lengthy Hiring Process:</td>
        <td>Arranging interviews required coordinating with non-Spanish speaking hiring managers, delaying the assessment and decision-making process.</td>
      </tr>
    </tbody>
  </table>
  <p>
    These challenges resulted in a prolonged hiring cycle, reduced efficiency, and hindered the company's ability to onboard quality candidates swiftly.
  </p>
</div>
<h3 className="case-subtitle">Implementing VettedVoice’s Solution</h3>
          <div className="case-body">
          VettedVoice introduced a comprehensive Voice AI solution tailored to MS Construction's
          specific needs:
  <table className="ideas-table">
    <tbody>
      <tr>
        <td className="lead-in">Multi-Lingual Voice AI Job Application Intake:</td>
        <td>A dedicated phone number powered by
VettedVoice’s Voice AI allows candidates to apply for jobs in their preferred language,
primarily Spanish or English.</td>
      </tr>
      <tr>
        <td className="lead-in">Complex Screening Assistant:</td>
        <td>The AI handles intricate screening questions, eliminating the
        need for HR intervention during the initial application phase.</td>
      </tr>
      <tr>
        <td className="lead-in">Seamless Integration with ATS:</td>
        <td>Completed applications are automatically sent to MS
        Construction’s ATS, ensuring a smooth transition from application to hiring.</td>
      </tr>
      <tr>
        <td className="lead-in">Enhanced Interview Process:</td>
        <td>VettedVoice’s AI facilitates initial interviews, providing hiring
        managers with deeper insights without the language barrier.</td>
      </tr>
      <tr>
        <td className="lead-in">24/7 Availability:</td>
        <td>Candidates can apply and complete interviews at their convenience, even
        during off-hours, reducing the dependency on HR availability.</td>
      </tr>
    </tbody>
  </table>
</div>

<h3 className="case-subtitle">Results Achieved</h3>
          <div className="case-body">
          The implementation of VettedVoice's Voice AI solution delivered outstanding outcomes for
MS Construction:
  <table className="ideas-table">
    <tbody>
      <tr>
        <td className="lead-in">Streamlined Application Process:</td>
        <td>Candidates, regardless of language proficiency or
technical skills, could easily apply via a simple phone call, minimizing reliance on paper
applications and in-person visits.</td>
      </tr>
      <tr>
        <td className="lead-in">Reduced HR Burden:</td>
        <td>TBy automating the initial application and screening processes, HR
        personnel could focus on strategic initiatives rather than routine application assistance.</td>
      </tr>
      <tr>
        <td className="lead-in">Faster Hiring Decisions:</td>
        <td>Hiring managers received comprehensive application data and
        insights promptly, enabling quicker and more informed hiring decisions.</td>
      </tr>
      <tr>
        <td className="lead-in">Improved Hiring Quality:</td>
        <td>The ability to conduct more thorough assessments through the AI-
        driven interview process led to better matching of candidates to job roles.</td>
      </tr>
      <tr>
        <td className="lead-in">Significant Efficiency Gain:</td>
        <td>The streamlined recruitment process resulted in a 41%
        increase in quality hires delivered faster than before.</td>
      </tr>
    </tbody>
  </table>
</div>
<h3 className="case-subtitle">Conclusion</h3>
          <p className="case-body">VettedVoice empowered MS Construction to overcome significant recruitment challenges by leveraging multi-lingual Voice AI technology. This partnership not only enhanced the
          efficiency and effectiveness of MS Construction’s hiring process but also ensured inclusivity and accessibility for a diverse candidate pool. The impressive 74% increase in quality hires underscores the transformative impact of VettedVoice’s innovative solutions.</p>
          <p className="case-body">“We look forward to adding additional services to continue upgrading and streamlining our
          recruitment processes.” SA, HR at MS Construction</p>
          <h3 className="case-subtitle">Why Choose VettedVoice for Your Recruitment Needs?</h3>
          <div className="case-body">
  <table className="ideas-table">
    <tbody>
      <tr>
        <td className="lead-in">Multi-Lingual Support:</td>
        <td>Cater to a diverse workforce with seamless language integration.</td>
      </tr>
      <tr>
        <td className="lead-in">AI-Driven Efficiency:</td>
        <td>Automate routine tasks to free up valuable HR resources.</td>
      </tr>
      <tr>
        <td className="lead-in">Comprehensive Screening:</td>
        <td>Conduct in-depth assessments without language or technical
        barriers.</td>
      </tr>
      <tr>
        <td className="lead-in">Scalable Solutions:</td>
        <td>Adaptable to companies of all sizes and industries aiming for growth.</td>
      </tr>
    </tbody>
  </table>
</div>
<h3 className="case-subtitle">Ready to revolutionize your recruitment process?</h3>
          <p className="case-body">Contact VettedVoice today to discover how our Voice AI solutions can drive your hiring
          success!</p>
          <div className="cs-video-wrapper">
  <Link to="/contact">
    <video
      className="cs-video"
      src={Walkin}
      autoPlay
      loop
      muted
      playsInline
    ></video>
  </Link>
</div>
        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default CaseStudy;
