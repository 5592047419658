// import React, { useContext } from 'react';
// import { Navigate } from 'react-router-dom';
// import { AuthContext } from '../contexts/AuthContext'; // Assuming you are using AuthContext

// function ProtectedRoute({ children, requireAdmin = false }) {
//   const { auth } = useContext(AuthContext); // Retrieve auth from context
//   const token = localStorage.getItem('token'); // Retrieve the token from localStorage

//   if (!token) {
//     // If no token is found, redirect to login page
//     return <Navigate to="/login" replace />;
//   }

//   // If the route requires admin access, check if the user has the Admin role
//   if (requireAdmin && auth.user?.userRole !== 'Admin') {
//     return <Navigate to="/login" replace />; // Redirect if the user is not an admin
//   }

//   // If token exists and user has proper access, render the child component
//   return children;
// }

// export default ProtectedRoute;

// src/components/ProtectedRoute.js

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, requireAdmin = false }) {
  // Access authentication state from Redux
  const { isLoggedIn, user, loading } = useSelector((state) => state.auth);

  // Optionally, display a loading indicator while auth state is being determined
  if (loading) {
    return <p>Loading...</p>; // Replace with a spinner or loader if preferred
  }

  // If not logged in, redirect to the login page
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // If admin access is required, verify the user's role
  if (requireAdmin && user?.userRole !== 'Admin') {
    // Optionally, you can redirect to a "Not Authorized" page or another appropriate route
    return <Navigate to="/dashboard" replace />;
  }

  // If all checks pass, render the child components
  return children;
}

export default ProtectedRoute;

