export const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE';

// Action Creators
export const fetchConfigRequest = () => ({ type: FETCH_CONFIG_REQUEST });
export const fetchConfigSuccess = (config) => ({
  type: FETCH_CONFIG_SUCCESS,
  payload: config,
});
export const fetchConfigFailure = (error) => ({
  type: FETCH_CONFIG_FAILURE,
  payload: error,
});
