
// export default Register;
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Import Redux hooks
//import { registerRequested } from '../redux/actions/registerActions'; 
import { authRequested } from '../redux/actions/authActions';
import '../css/Register.css';

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Select Redux state for registration
  // const { success, error, isSubmitting } = useSelector((state) => state.register);
  const { error, loading, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate]);


  // Redirect to dashboard if registration is successful
  // useEffect(() => {
  //   if (success) {
  //     navigate('/dashboard');
  //   }
  // }, [success, navigate]);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
      lastName: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
    }),
    onSubmit: (values) => {
      console.log("Im an registering.........")
      dispatch(authRequested({ credentials: values, actionType: 'register' })); // Dispatch Redux action
    },
  });

  return (
    <div className="register-container">
      <h2>Register</h2>

      {/* Display error message */}
      {error && <p className="register-error-message">{error}</p>}

      <form onSubmit={formik.handleSubmit}>
        {/* First Name */}
        <div className="register-form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            className={`register-input ${formik.touched.firstName && formik.errors.firstName ? 'register-input-error' : ''}`}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className="register-error">{formik.errors.firstName}</div>
          )}
        </div>

        {/* Last Name */}
        <div className="register-form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            className={`register-input ${formik.touched.lastName && formik.errors.lastName ? 'register-input-error' : ''}`}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className="register-error">{formik.errors.lastName}</div>
          )}
        </div>

        {/* Email */}
        <div className="register-form-group">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={`register-input ${formik.touched.email && formik.errors.email ? 'register-input-error' : ''}`}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="register-error">{formik.errors.email}</div>
          )}
        </div>

        {/* Password */}
        <div className="register-form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className={`register-input ${formik.touched.password && formik.errors.password ? 'register-input-error' : ''}`}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="register-error">{formik.errors.password}</div>
          )}
        </div>

        {/* Submit Button */}
        {/* <button type="submit" className="register-submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'Registering...' : 'Register'}
        </button> */}
         <button type="submit" className="register-submit-button" disabled={formik.isSubmitting || loading}>
          {loading ? 'Registering...' : 'Register'}
        </button>
      </form>
        {/* New "Have an account?" section */}
        <div className="login-prompt">
        <span>Have an account? </span>
        <a href='/login' className="login-link">Sign in</a>
      </div>
    </div>
  );
}

export default Register;


