const initialState = {
    isLoggedIn: false,
    user: null,
    token: null,
    error: null,
    loading: false, 
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'AUTH_REQUESTED':
        return { ...state, loading: true, error: null };
  
      case 'AUTH_SUCCESS':
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload.user,
          token: action.payload.token,
          loading: false,
          error: null,
        };
  
      case 'AUTH_FAILED':
        return { ...state, loading: false, error: action.payload };
  
        case 'LOGOUT_REQUESTED':
          return { ...state, loading: true, error: null };
    
        case 'LOGOUT_SUCCESS':
          return initialState; // Reset to initial state on logout
    
        case 'LOGOUT_FAILED':
          return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default authReducer;
  