import React from 'react';
//import Navbar from '../../components/Navbar';
import './Manufacturing.css';
import LinksContainer from '../../components/LinksContainer';

function Manufacturing() {
  return (
    <div className="manuf-page">
      <div className="manuf-container">
        <div className="gloves-container">
          {/* You can uncomment and add an image if necessary */}
          {/* <img className="pat-gloves" src="/img/pat-gloves.jpg" alt="Pat Gloves" /> */}
        </div>
        <div className="manuf-container">
          <h3 className="manuf-title">Manufacturing</h3>
          <p className="manuf-body">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint saepe unde maxime labore rerum voluptatum molestiae accusantium ullam alias ducimus! Doloribus impedit nobis nemo, vero quod fuga quos architecto modi?</p>
          <p className="manuf-body">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero enim eaque praesentium nam suscipit voluptate tenetur expedita ipsa rem! Necessitatibus quaerat officiis sapiente optio nam, consectetur rerum hic nulla culpa aspernatur dicta exercitationem non nesciunt vero at quos, similique voluptates neque. Expedita exercitationem consectetur, dolorem vitae veritatis velit cum impedit eius numquam alias praesentium delectus magnam aperiam. Accusantium, explicabo eaque..</p>
          <p className="manuf-body">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid dicta nisi numquam praesentium maxime enim quibusdam aspernatur, possimus iure a fugit excepturi mollitia quae? Animi hic facilis, iusto repellendus aperiam voluptatem voluptas deserunt nobis natus cumque. Dicta accusamus esse consequatur debitis nesciunt possimus provident, quia sunt! Rerum, officiis. Hic, ea laborum necessitatibus veniam unde asperiores nam voluptatem ad delectus optio?.</p>
          <p className="manuf-body">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, natus rem? Optio quibusdam voluptates, sapiente numquam illum mollitia inventore tempora aliquid quasi nobis laborum explicabo dolorum nisi, voluptas dolor id..</p>
          <p className="manuf-body">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos obcaecati eius numquam! Ad accusamus in, blanditiis facilis nobis dignissimos ullam reiciendis dolore doloribus, amet neque suscipit nemo nulla quasi. Vitae..</p>
        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default Manufacturing;
