
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginRequested } from '../redux/actions/loginActions'; 
import { authRequested } from '../redux/actions/authActions';
import { px } from 'framer-motion';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get login state from Redux
  const { isLoggedIn, error, loading, user } = useSelector((state) => state.auth);

  // Redirect to dashboard on successful login
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate('/dashboard');
  //   }
  // }, [isLoggedIn, navigate]);
  useEffect(() => {
    if (isLoggedIn) {
      if (user?.userRole === 'Admin') {
        navigate('/admin-dashboard');
      } else {
        navigate('/dashboard');
      }
    }
  }, [isLoggedIn, user, navigate]);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, []);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Required'),
    }),
    onSubmit: (values) => {
      // Dispatch Redux action to trigger login
      dispatch(authRequested({ credentials: values, actionType: 'login' }));
    },
  });

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Login</h2>

      {/* Display login error if present */}
      {error && <p style={styles.error}>{error}</p>}

      <form onSubmit={formik.handleSubmit} style={styles.form}>
        {/* Email input */}
        <div style={styles.inputGroup}>
          <label htmlFor="email" style={styles.label}>Email:</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={formik.touched.email && formik.errors.email ? 'input-error' : ''}
            style={styles.input}
            placeholder="Enter your email"
          />
          {formik.touched.email && formik.errors.email ? (
            <div style={styles.error}>{formik.errors.email}</div>
          ) : null}
        </div>

        {/* Password input */}
        <div style={styles.inputGroup}>
          <label htmlFor="password" style={styles.label}>Password:</label>
          <input
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className={formik.touched.password && formik.errors.password ? 'input-error' : ''}
            style={styles.input}
            placeholder="Enter your password"
          />
          {formik.touched.password && formik.errors.password ? (
            <div style={styles.error}>{formik.errors.password}</div>
          ) : null}
        </div>

        {/* Submit button */}
        {/* <button type="submit" disabled={formik.isSubmitting} style={styles.button}>
          {formik.isSubmitting ? 'Logging in...' : 'Login'}
        </button> */}
         <button type="submit" disabled={loading} style={styles.button}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
       {/* New section for "Sign up" link */}
       <div style={styles.signupPrompt}>
        <span>New to VettedVoice? </span>
        <a href='/register' style={styles.signupLink}>
          Sign up
        </a>
      </div>
    </div>
  );
}

// Simple inline styles
const styles = {
  container: {
    maxWidth: '400px',
    margin: '50px auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  heading: {
    color: '#f9f9f9',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    marginBottom: '5px',
    display: 'block',
    fontWeight: 'bold',
    color: '#f9f9f9',
  },
  input: {
    padding: '8px',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  error: {
    color: 'red',
    marginBottom: '15px',
  },
  signupPrompt: {
    fontFamily: 'Poppins, sans-serif',
    color: '#f9f9f9', 
    fontWeight: 'bold' 
  },
  signupLink: {
    color: '#007bff',
    textDecoration: 'none',
    marginLeft: '5px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold'
  },
};

export default Login;

