import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/BlogList.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LinksContainer from '../components/LinksContainer';

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      console.log('API URL:', fetchUrl);
      const response = await axios.get(`${fetchUrl}/api/blog`);
      const data = response.data;

      if (Array.isArray(data)) {
        const sortedBlogs = data.sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
        setBlogs(sortedBlogs);
      } else {
        setBlogs([]);
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setError('Failed to fetch blogs.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // Handle opening modal based on URL parameters on initial render
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const blogId = urlParams.get('p');
    const modalParam = urlParams.get('pm');

    if (blogId && modalParam === 'c') {
      const blog = blogs.find((b) => b.id === blogId);
      if (blog) {
        openModal(blog, false); // Pass false to avoid pushing history again
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogs]); // Run this effect when blogs are fetched

  const openModal = (blog, pushState = true) => {
    setSelectedBlog(blog);
    setIsModalOpen(true);
    document.body.classList.add('no-scroll'); // Disable body scrolling

    if (pushState) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('p', blog.id);
      urlParams.set('pm', 'c');
      window.history.pushState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBlog(null);
    document.body.classList.remove('no-scroll'); // Enable body scrolling

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('p');
    urlParams.delete('pm');

    const newSearch = urlParams.toString();
    const newUrl = newSearch ? `${window.location.pathname}?${newSearch}` : window.location.pathname;

    window.history.pushState(null, '', newUrl);
  };

  // Optional: Close modal on Escape key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isModalOpen) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isModalOpen]);

  // Optional: Close modal when clicking outside the modal content
  const handleOutsideClick = (e) => {
    if (e.target.className === 'modal') {
      closeModal();
    }
  };

  // Handle browser navigation (back/forward buttons)
  useEffect(() => {
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const blogId = urlParams.get('p');
      const modalParam = urlParams.get('pm');

      if (blogId && modalParam === 'c') {
        const blog = blogs.find((b) => b.id === blogId);
        if (blog) {
          openModal(blog, false); // Pass false to avoid pushing history again
        }
      } else {
        if (isModalOpen) {
          closeModal();
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogs, isModalOpen]);

  if (loading) {
    return <p>Loading blogs...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="blog-list-container">
      <h1>Blog</h1>
      {blogs.length === 0 ? (
        <p>No Blogs available</p>
      ) : (
        <div className="blog-card-grid">
          {blogs.map((blog) => (
            <div key={blog.id} className="blog-card" onClick={() => openModal(blog)}>
               <LazyLoadImage
                alt="Hero"
                src={blog.hero_image}
                effect="blur" 
                className="blog-card-image"
              />
              <div className="blog-card-content">
                <h2 className="blog-title">{blog.title}</h2>
                <p className="blog-info">{blog.info}</p>
                <p className="blog-published">
                  <strong>Published:</strong> {new Date(blog.published_date).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))}
          
        </div>
      )}

{isModalOpen && selectedBlog && (
        <div className="modal" onClick={handleOutsideClick}>
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <h2>{selectedBlog.title}</h2>
            <p>
              <strong>Published:</strong> {new Date(selectedBlog.published_date).toLocaleDateString()}
            </p>
            <img src={selectedBlog.hero_image} alt="Hero" loading='lazy' className="modal-hero-image" />
            <div className="modal-blog-content" dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogList;
