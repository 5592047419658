import React, { useState, useEffect, useRef } from 'react';
//import Navbar from '../../components/Navbar';
import './Product.css';
import LinksContainer from '../../components/LinksContainer';
import Lacy from '../../img/VVLacyCellPhone.png';
import VVIcon from '../../img/UpdatedVee.png';
import Avatar from '@mui/material/Avatar';
import throttle from 'lodash.throttle';
import useIsMobile from '../../components/useIsMobile';

function Product() {
  const [visibleChats, setVisibleChats] = useState([false, false, false, false]);

  // Ref for the phone image to track its position
  const phoneRef = useRef(null);

  const isMobile = useIsMobile(768);

  useEffect(() => {
    // Define threshold values for desktop and mobile
    const desktopThresholds = [
      125,  // Bubble 1
      35,   // Bubble 2
      0,    // Bubble 3
      -75,  // Bubble 4
    ];

    const mobileThresholds = [
      -20,  // Bubble 1 (Adjusted for mobile)
      -300,   // Bubble 2
      -700,    // Bubble 3
      -1100, // Bubble 4
    ];

    // Select appropriate thresholds based on screen size
    const selectedThresholds = isMobile ? mobileThresholds : desktopThresholds;

    console.log('Selected Thresholds:', selectedThresholds);

    // Scroll handler to update chat bubble visibility
    const handleScroll = () => {
      if (!phoneRef.current) return;

      const phoneRect = phoneRef.current.getBoundingClientRect();

      // Calculate the visibility based on thresholds
      const newVisibleChats = [
        phoneRect.top < selectedThresholds[0], // Bubble 1
        phoneRect.top < selectedThresholds[1], // Bubble 2
        phoneRect.top < selectedThresholds[2], // Bubble 3
        phoneRect.top < selectedThresholds[3], // Bubble 4
      ];
      console.log('Phone Top:', phoneRect.top);
      // Update the state only if there's a change
      setVisibleChats((prevVisibleChats) => {
        // Check for any changes
        for (let i = 0; i < visibleChats.length; i++) {
          if (prevVisibleChats[i] !== newVisibleChats[i]) {
            return newVisibleChats;
          }
        }
        return prevVisibleChats;
      });
    };

    // Throttle the scroll handler to improve performance
    const throttledHandleScroll = throttle(handleScroll, 100); // Adjust the delay as needed

    // Initial check in case the user has already scrolled
    handleScroll();

    // Attach the scroll event listener
    window.addEventListener('scroll', throttledHandleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel(); // Cancel any pending throttled calls
    };
  }, [visibleChats.length]); // Depend on the length to avoid unnecessary re-renders

  return (
    <div className='product-90-page'>
      <div className="product-phone-interact">
        <div className="phone-wrapper" ref={phoneRef}>
          <img className="product-phone" src={Lacy} alt="A cell phone" />
        </div>

        {/* Chat bubbles positioned to the right of the phone */}
        <div className="chat-bubbles">
          {/* Chat Bubble 1 */}
          <div
            className={`chat-bubble user ${visibleChats[0] ? 'visible' : ''}`}
          >
            <div className="chat-content">
              <Avatar sx={{ bgcolor: '#00796b', width: 40, height: 40 }}>NJ</Avatar>
              <div>
                <span className="chat-name">Nick Jones</span>
                <p>Thank you for your time. You made this so easy.</p>
              </div>
            </div>
          </div>

          {/* Chat Bubble 2 */}
          <div
            className={`chat-bubble agent ${visibleChats[1] ? 'visible' : ''}`}
          >
            <div className="chat-content">
              <img src={VVIcon} alt="Vetted Voice Icon" className="chat-avatar" />
              <div>
                <span className="chat-name">Lacy</span>
                <p>
                  It was great helping you apply and screen for the Crew Member position at XYZ
                  Company today. Now let's get you scheduled on the hiring manager's calendar.
                  What's your availability this week?
                </p>
              </div>
            </div>
          </div>

          {/* Chat Bubble 3 */}
          <div
            className={`chat-bubble user ${visibleChats[2] ? 'visible' : ''}`}
          >
            <div className="chat-content">
              <Avatar sx={{ bgcolor: '#00796b', width: 40, height: 40 }}>NJ</Avatar>
              <div>
                <span className="chat-name">Nick Jones</span>
                <p>
                  Great! Thanks for answering all of my questions too - this is the easiest
                  employment application experience I've ever had! I'm available anytime today if
                  possible.
                </p>
              </div>
            </div>
          </div>

          {/* Chat Bubble 4 */}
          <div
            className={`chat-bubble agent ${visibleChats[3] ? 'visible' : ''}`}
          >
            <div className="chat-content">
              <img src={VVIcon} alt="Vetted Voice Icon" className="chat-avatar" />
              <div>
                <span className="chat-name">Lacy</span>
                <p>
                  I'm so glad to hear you've had such a great experience! Looks like there's an
                  opening today at 1pm. I've booked you on the calendar and you should receive a
                  message with the directions. If you don't have any more questions, I hope you have
                  a great rest of your day, and good luck!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div className="product-table">
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>VettedVoice</th>
                <th>IVR</th>
                <th>Chat</th>
                <th>Text to Apply</th>
                <th>Online</th>
                <th>Paper</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2 Way Conversation</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="possible">Possible</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
              </tr>
              <tr>
                <td>Speed</td>
                <td><span className="speed">Fastest</span></td>
                <td><span className="speed">Average</span></td>
                <td><span className="speed">Fast</span></td>
                <td><span className="speed">Fast</span></td>
                <td><span className="speed">Slow</span></td>
                <td><span className="speed">Slowest</span></td>
              </tr>
              <tr>
                <td>Simple Screen</td>
                <td><span className="check">Yes</span></td>
                <td><span className="check">Yes</span></td>
                <td><span className="check">Yes</span></td>
                <td><span className="check">Yes</span></td>
                <td><span className="check">Yes</span></td>
                <td><span className="possible">Possible</span></td>
              </tr>
              <tr>
                <td>Complex Screen</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
              </tr>
              <tr>
                <td>Full Interviewing</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
              </tr>
              <tr>
                <td>Evaluation</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
              </tr>
              <tr>
                <td>Complete Application</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="check">Yes</span></td>
              </tr>
              <tr>
                <td>Eliminates Need for Staff Assistance</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
              </tr>
              <tr>
                <td>Best Candidate Experience</td>
                <td><span className="check">Yes</span></td>
                <td><span className="x">No</span></td>
                <td><span className="ok">Ok</span></td>
                <td><span className="ok">Ok</span></td>
                <td><span className="x">No</span></td>
                <td><span className="x">No</span></td>
              </tr>
            </tbody>
          </table>
          </div>
          <div className='final-notes'>
            <p className='product-90-body'>
            Completely automate the recruitment process from the moment the candidate shows
            interest, to application, screening and interviewing from start to finish with VettedVoice.
            </p>
            <p className='product-90-body'>
            VettedVoice is also ATS agnostic.
            </p>
            <p className='product-90-body'>So, give us a try today! Multiple plans available to fit your needs.</p>
          </div>
        <LinksContainer />
        </div>
      );
}

export default Product;
