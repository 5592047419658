import React from 'react';
//import Navbar from '../../components/Navbar';
import './FAQ.css';
import LinksContainer from '../../components/LinksContainer';

function FAQ() {
  return (
    <div className="faq-page">
      <div className="faq-container">
        <div className="faq-container">
          <h3 className="faq-title">FAQ</h3>
          <h3 className="faq-subtitle">I’m a hiring manager or recruiter, how is VettedVoice going to help me?</h3>
          <p className="faq-body">Our goal is to provide a seamless automated experience right up to the time you first meet the candidate face to face, giving you the confidence to be able to hire on the spot!  As a result, we can advise in setting up your workflows with VettedVoice to best fit your process, fully Free of charge! This will ensure that you experience the benefits and ROI of VettedVoice immediately on day 1!</p>
          <h3 className="faq-subtitle">How are you different from most other recruitment tools?</h3>
          <p className="faq-body">Current tools have been helpful in speeding up the recruitment process, however they lack
          the ability to take it to the next level to provide a fully seamless experience. For more information,
          see our Product page.</p>
          <h3 className="faq-subtitle">Why should we choose VettedVoice over other similar products?</h3>
          <p className="faq-body">There are other similar products out there that may be somewhat helpful offering
          something better than the current tools offered in the market. However, none other than VettedVoice offer a full cycle experience that is much more cost effective than our competitors. We’re simply the best, but don’t just take our word for it – Try us today! Contact us to get started!</p>
          <h3 className="faq-subtitle">Do you integrate with my ATS?</h3>
          <p className="faq-body">VettedVoice is ATS agnostic. Whether you are using software provided by your payroll
          provider like ADP, using a legacy enterprise product such as Taleo or a newer modern incumbent system like Recruitee chances are we can provide an ideal workflow. To find out more Contact us today!</p>
          <h3 className="faq-subtitle">What languages do you offer?</h3>
          <p className="faq-body">We offer numerous languages and are adding more every day, however our most
          commonly used is Spanish. If you would like to know if we can incorporate your language of choice, please Contact us.</p>
          <h3 className="faq-subtitle">Can I get my translation from language X to language Y?</h3>
          <p className="faq-body">Currently all languages that require translation are translated from that language into English only. At this time, you cannot translate English into another language nor have two non-English languages translate into the other.</p>
          <h3 className="faq-subtitle">How much do you cost?</h3>
          <p className="faq-body">We serve business in all industries of all sizes. Chances are we have a plan that works for you – so Contact us today for more info! We offer month to month plans where you can cancel anytime.</p>
        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default FAQ;
