// // src/components/LoadingOverlay.js

import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

export default function LoadingOverlay({ open }) {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it's above all other components
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

LoadingOverlay.propTypes = {
  open: PropTypes.bool.isRequired,
};
