// // src/components/Dashboard.js
//this is the original dashboard so you can see how it worked and give me ideas on passing the distinct user per register and login to reflect in the newer, improved dashboard
// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { logoutRequested } from '../redux/actions/authActions';

// function Dashboard() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   // Access Redux state
//   const { user, isLoggedIn, loading } = useSelector((state) => state.auth);

//   // Redirect to login if not logged in
//   useEffect(() => {
//     if (!isLoggedIn && !loading) {
//       navigate('/login');
//     }
//   }, [isLoggedIn, loading, navigate]);

//   // Handle Logout
//   const handleLogout = () => {
//     dispatch(logoutRequested());
//     navigate('/login');
//   };

//   // Handle cases where user data is not available yet
//   if (loading || !user) {
//     return <p>Loading user data...</p>;
//   }

//   return (
//     <div style={styles.container}>
//       <h2 style={styles.title}>Dashboard</h2>
//       <p style={styles.welcome}>
//         Welcome, {user.firstName} {user.lastName}!
//       </p>
//       {user.userRole === 'Admin' && (
//         <button onClick={() => navigate('/admin-dashboard')} style={styles.adminButton}>
//           Admin Dashboard
//         </button>
//       )}
//       <button onClick={handleLogout} style={styles.button}>Logout</button>
//     </div>
//   );
// }

// const styles = {
//   container: {
//     maxWidth: '600px',
//     margin: '50px auto',
//     padding: '20px',
//     border: '1px solid #ddd',
//     borderRadius: '5px',
//     boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//     textAlign: 'center',
//   },
//   button: {
//     padding: '10px 20px',
//     backgroundColor: '#dc3545',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     fontWeight: 'bold',
//     marginTop: '20px',
//   },
//   title: { color: '#f9f9f9' },
//   welcome: { color: '#f9f9f9' },
//   adminButton: {
//     padding: '10px 20px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     fontWeight: 'bold',
//     marginTop: '20px',
//     marginBottom: '20px',
//   },
// };

// export default Dashboard;


import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './AppNavbar';
import Header from './Header';
import MainGrid from './MainGrid';
import SideMenu from './SideMenu';
import SideMenuMobile from './SideMenuMobile';
import AppTheme from '../shared-theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import { SiTruenas } from 'react-icons/si';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props) {
  const navigate = useNavigate();
  const { user, isLoggedIn, loading } = useSelector((state) => state.auth);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!isLoggedIn && !loading) {
      navigate('/login');
    }
  }, [isLoggedIn, loading, navigate]);

  if (loading || !user) {
    return <p>Loading user data...</p>;
  }

  if (user) {
    console.log(user.firstName, user.lastName, user.email);
  }
  const handleMenuClick = () => {
    setIsMobileMenuOpen((prev) => !prev);
    console.log('Menu click toggled to:', !isMobileMenuOpen);
  };

  // Function to explicitly open or close the drawer
  const toggleDrawer = (open) => {
    setIsMobileMenuOpen(open);
    console.log(`Drawer set to ${open ? 'open' : 'closed'}`);
  };
  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
      <SideMenu user={user} />
      <SideMenuMobile
          user={user}
          open={isMobileMenuOpen}
          toggleDrawer={toggleDrawer}
        />

        {/* Navbar */}
        <AppNavbar onMenuClick={handleMenuClick} />
      
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <MainGrid />
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
