import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_CONFIG_REQUEST,
  fetchConfigSuccess,
  fetchConfigFailure,
} from '../actions/configActions';

// API Call to Fetch Config
async function fetchConfigApi() {
  const response = await fetch('/config', { credentials: 'include' });
  if (!response.ok) {
    throw new Error('Failed to fetch config');
  }
  return await response.json();
}

// Worker Saga: Handles the API Call
function* fetchConfigSaga() {
  try {
    const config = yield call(fetchConfigApi);
    yield put(fetchConfigSuccess(config));
  } catch (error) {
    yield put(fetchConfigFailure(error.message));
  }
}

// Watcher Saga: Listens for FETCH_CONFIG_REQUEST
export function* watchFetchConfig() {
  yield takeLatest(FETCH_CONFIG_REQUEST, fetchConfigSaga);
}
