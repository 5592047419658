// src/redux/slices/uiSlice.js

import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    loading: false, // Global loading state
  },
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
  },
});

export const { startLoading, stopLoading } = uiSlice.actions;

export default uiSlice.reducer;
// src/redux/slices/uiSlice.js

// import { createSlice } from '@reduxjs/toolkit';

// const uiSlice = createSlice({
//   name: 'ui',
//   initialState: {
//     loading: false,       // General UI loading (e.g., authentication)
//     routeLoading: false,  // Loading state for route transitions
//   },
//   reducers: {
//     startLoading(state) {
//       state.loading = true;
//     },
//     stopLoading(state) {
//       state.loading = false;
//     },
//     startRouteLoading(state) {
//       state.routeLoading = true;
//     },
//     stopRouteLoading(state) {
//       state.routeLoading = false;
//     },
//   },
// });

// export const { startLoading, stopLoading, startRouteLoading, stopRouteLoading } = uiSlice.actions;

// export default uiSlice.reducer;
