import React from 'react';
//import Navbar from '../../components/Navbar';
import './About.css'; 
import People from '../../img/neonppl.png';
import LinksContainer from '../../components/LinksContainer';

function About() {
  return (
    <div className="about-page">
      <div className="about-container">
        <div className="about-container">
          <h3 className="about-title">Our Values</h3>
          <h3 className="about-subtitle">User-Centricity</h3>
          <p className="about-body">We put the user’s needs and experiences first, ensuring the technology serves and
          enhances their journey, whether they are job seekers, recruiters, or hiring managers.</p>
          <h3 className="about-subtitle">Transparency and Trust</h3>
          <p className="about-body">We want long-standing relationships with our users, and as a result, we strongly believe
          that it starts with transparency and trust in all we do.</p>
          <h3 className="about-subtitle">Innovation with Integrity</h3>
          <p className="about-body">Continuously pushing the boundaries of technology while upholding ethical standards, ensuring that AI-driven solutions are fair, and aligned with best hiring practices.</p>
          <h3 className="about-subtitle">Continuous Learning and Adaptability</h3>
          <p className="about-body">Embracing feedback and rapidly evolving in response to user needs and advancements in
          AI technology, ensuring the platform remains relevant, effective, and beneficial in a dynamic employment landscape.</p>
          <div className="about-img-div">
            <img alt="That's my people!  They're neon like that" src={People} className="neon-peeps-img" />
          </div>
          <p className="about-body">At VettedVoice, we are revolutionizing the hiring process by leveraging the power of voice AI technology. Our mission is to simplify and streamline the job application and interview screening experience for both candidates and employers.</p>
          <p className="about-body">We understand that traditional hiring methods can be time-consuming, inefficient, and often biased. That's why we've created a service that allows candidates to showcase their skills, experience, and personality through natural voice conversations. Our AI-powered system can analyze these conversations to identify the most suitable candidates for each position, saving employers valuable time and resources.</p>
          <p className="about-body">We prioritize the security and privacy of all user data. VettedVoice complies with industry-standard security protocols to protect sensitive information.</p>
          <p className="about-body">We are committed to providing a seamless and intuitive user experience for both candidates and employers. Our service is easy to use and accessible from any device, making it convenient for everyone involved in the hiring process.</p>
          <p className="about-body">Join VettedVoice today and experience the future of hiring. Let us help you find the perfect fit for your organization. Together, let's make the hiring process more efficient, effective, and enjoyable for everyone.</p>
        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default About;
